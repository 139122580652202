.accordion {
  border: 1px solid $gray;
  border-radius: 1rem;
  padding: 2rem;
  transition: all $transition-timing;

  @media (min-width: $screen-sm-min) {
    padding: 2.5rem;
  }

  $this: &;

  &--green {
    border-color: $green;

    #{$this}__header {
      color: $green;
    }

    &#{$this}--is-open {
      background-color: $green-lightest;
    }
  }

  &--is-open {
    background-color: $gray-lightest;

    #{$this}__header i.fas {
      transform: rotate(180deg);
    }

    #{$this}__content {
      max-height: 100rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    color: $gray;
    line-height: $line-height-tight;

    p {
      padding-bottom: 0;
    }

    i.fas {
      transition: all $transition-timing;
    }

    &:hover, &:active {
      cursor: pointer;
    }
  }

  &__content {
    max-height: 0;
    transition: all $transition-timing;
    overflow: hidden;

    p:last-child {
      padding-bottom: 0;
    }
  }
}