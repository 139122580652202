.cb--timeline {
  &__timeline {
    padding-top: 4rem;

    &__item {
      padding: 0 2rem 5rem 5rem;
      position: relative;
      margin-left: $bit-padding;

      @media (min-width: $screen-md-min) {
        width: 50%;
        margin-left: 50%;
        padding: 0 5rem;

        figure {
          margin-bottom: -15rem;
        }

        &:nth-child(2n) {
          text-align: right;
          margin-left: 0;

          &::before {
            left: unset;
            right: -1rem;
          }

          &::after {
            left: unset;
            right: -1px;
          }
        }

        &:last-child {
          text-align: center;
          left: 50%;
          transform: translateX(-50%);
          padding-top: 5rem;
          width: 65%;

          &::before {
            left: 50%;
            transform: translateX(-50%);
            right: unset;
            top: 0;
          }

          &::after {
            display: none;
          }
        }

        &:nth-last-child(2) figure {
          margin-bottom: 0;
        }
      }

      &::before {
        content: " ";
        position: absolute;
        top: .4rem;
        left: -1rem;
        width: 2rem;
        height: 2rem;
        background-color: $gray;
        border-radius: 50%;
        border: 4px solid $gray-lightest;
        z-index: 2;
      }

      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: -1px;
        width: 2px;
        height: 100%;
        background-color: $gray;
        z-index: 1;
      }

      $this: &;

      &:first-child {
        &::after {
          top: -4rem;
          height: calc(100% + 4rem);
        }
      }

      &:last-child {
        &::after {
          height: 6rem;
        }
      }

      &:nth-child(2), &:nth-child(5) {
        &::before {
          background-color: $green-light;
        }

        #{$this}__title {
          color: $green-light;
        }
      }

      &:nth-child(3), &:nth-child(6) {
        &::before {
          background-color: $green;
        }

        #{$this}__title {
          color: $green;
        }
      }

      &__title {
        //
      }

      figure {
        border-radius: 2rem;
        overflow: hidden;
      }
    }
  }

}