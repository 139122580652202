@import '../mixins/base-mixins';

//== Typography
//====================================================
html {
  font-size: $font-size-html;
  scroll-padding-top: $navbar-mobile-height;

  @media (min-width: $screen-md-min) {
    scroll-padding-top: $navbar-desktop-height;
  }
}

body {
  @include responsive-font-size((($font-size-base-xxs), ($screen-sm-min, $font-size-base)));
  background: $white;
  font-family: $font-family-base;
  font-weight: $font-weight-light;
  line-height: $line-height-base;
  color: $font-color;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  padding-top: 0;
  padding-bottom: $bit-padding;
}

a {
  text-decoration: underline;
  -webkit-backface-visibility: hidden;
  color: $green;
  display: inline;
  transition: all $transition-timing;

  &:hover, &:active {
    color: darken($green, .5);
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $font-family-headings;
  line-height: $line-height-tight;
  color: $headings-color;
  font-weight: $font-weight-bold;
}

h1,
.h1 {
  @include h1;
}

h2, .h2 {
  @include h2;
}

h3, .h3 {
  @include h3;
}

h4, .h4 {
  @include h4;
}

h5, .h5 {
  @include h5;
}

h6, .h6 {
  @include h6;
}

strong {
  font-weight: $font-weight-demi;
}

.lead {
  @include responsive-font-size((($font-size-lead-xxs), ($screen-sm-min, $font-size-lead)));
  line-height: $line-height-base;
}

.caption {
  @include responsive-font-size((($font-size-caption-xxs), ($screen-sm-min, $font-size-caption)));
  opacity: .7;
}

blockquote {
  @include responsive-font-size((($font-size-quote-xxs), ($screen-sm-min, $font-size-quote)));
  font-family: $font-family-headings;
  line-height: $line-height-quote;

  p {
    margin-bottom: 0;
    padding-bottom: $bit-padding / 2;
    line-height: $line-height-quote;
  }
}

ol, ul {
  margin-bottom: $bit-padding * 2;
  margin-left: 2.5rem;

  li {
    margin-bottom: .5rem;
  }
}

hr, .hr {
  margin: 0 0 $bit-padding 0;
  border-top: 1px solid $gray-lightest;
}

small {
  @include responsive-font-size((($font-size-small-xxs), ($screen-sm-min, $font-size-small)));
}

table {
  tbody {
    border-bottom: 0;
  }

  thead, tbody, tbody tr {
    border-color: $gray-light;
  }

  tbody tr td, tbody tr th {
    padding: .5rem .3rem;
  }
}