.cb--steps_2 {
  $this: &;

  &[data-font-color="green"] {
    #{$this}__step {
      &__label {
        color: $green;
        border-color: $green;

        &::after {
          background-color: $green;
        }
      }

      &__title {
        color: $green;
      }

      &__box {
        border-color: $green;
      }
    }

    .swiper-slide::before {
      background-color: $green;
    }
  }

  &__title > * {
    @extend .h2;
  }

  &__slider {
    margin-right: - $bit-padding;
    margin-left: - $bit-padding;

    @media (min-width: $screen-md-min) {
      .swiper-pagination {
        display: none !important;
      }
    }

    .swiper-slide {
      position: relative;
      padding: $bit-padding;
      height: auto;

      @media (min-width: $screen-md-min) {
        padding: $bit-padding * 2 0;
      }

      &::before {
        content: " ";
        height: 2px;
        width: 100%;
        background-color: $gray;
        position: absolute;
        left: 0;
        top: 5.5rem;

        @media (min-width: $screen-md-min) {
          width: 2px;
          height: 100%;
          left: 4rem;
          top: 0;
        }
      }

      &:first-child {
        &::before {
          width: 50%;
          left: unset;
          right: 0;

          @media (min-width: $screen-md-min) {
            left: 4rem;
            right: unset;
            width: 2px;
            height: 50%;
            top: 50%
          }
        }
      }

      &:last-child {
        &::before {
          width: 50%;

          @media (min-width: $screen-md-min) {
            left: 4rem;
            right: unset;
            width: 2px;
            height: 50%;
          }
        }
      }
    }
  }

  &__step {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: $screen-md-min) {
      flex-direction: row;
      align-items: center;
    }

    &__label {
      position: relative;
      width: 8rem;
      height: 8rem;
      background-color: $white;
      border: 2px solid $gray;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      text-align: center;
      color: $gray;
      font-weight: $font-weight-bold;
      margin: 0 auto 4.6rem auto;
      box-shadow: rgba($black, .16) 0 .3rem .6rem;

      &::after {
        content: " ";
        width: 2px;
        height: 4.8rem;
        background-color: $gray;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -4.8rem;

        @media (min-width: $screen-md-min) {
          height: 2px;
          width: 4.8rem;
          left: unset;
          bottom: unset;
          right: -4.8rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      @media (min-width: $screen-md-min) {
        margin: 0 4.6rem 0 0;
      }
    }

    &__box {
      flex: 1;
      background-color: $white;
      border: 2px solid $gray;
      padding: 4rem;
      border-radius: $border-radius-large;
      font-weight: $font-weight-normal;

      p:last-child {
        padding-bottom: 0;
      }
    }

    &__title {
      font-weight: $font-weight-bold;
      color: $gray;
      @include responsive-font-size(((3rem), ($screen-sm-min, 3.2rem)));
      line-height: $line-height-super-tight;
    }
  }

  //&__slider {
  //  margin-right: - $bit-padding;
  //  margin-left: - $bit-padding;
  //
  //  .swiper-slide {
  //    padding: 3.9rem $bit-padding $bit-padding;
  //    height: auto;
  //  }
  //
  //  @media (min-width: $screen-sm-min) {
  //    margin-left: auto;
  //    margin-right: auto;
  //    max-width: 50rem;
  //  }
  //
  //  @media (min-width: $screen-md-min) {
  //    display: flex;
  //    justify-content: space-between;
  //    max-width: unset;
  //
  //    .swiper-wrapper > * {
  //      &:nth-child(2n) {
  //        padding-top: 8rem;
  //      }
  //
  //      &:nth-child(3n) {
  //        padding-top: 4rem;
  //      }
  //    }
  //  }
  //}

  //&__step {
  //  position: relative;
  //  background: $white;
  //  border: 3px solid $white;
  //  border-radius: 3rem;
  //  text-align: center;
  //  box-shadow: rgba($black, .16) .3rem .3rem .6rem;
  //  height: 100%;
  //
  //  @media (min-width: $screen-md-min) {
  //    height: auto;
  //  }
  //
  //  &__image {
  //    padding-bottom: 4rem;
  //    border-top-left-radius: 3rem;
  //    border-top-right-radius: 3rem;
  //    overflow: hidden;
  //
  //    img {
  //      object-fit: cover;
  //      width: 100%;
  //      height: 100%;
  //    }
  //  }
  //
  //  &__title {
  //    color: $gray;
  //    font-weight: $font-weight-bold;
  //    @include responsive-font-size(((2.5rem), ($screen-sm-min, 2.8rem)));
  //  }
  //
  //  &__text {
  //    padding: 0 2rem 2rem 2rem;
  //
  //    @media (min-width: $screen-sm-min) {
  //      padding: 0 3rem 2rem 3rem;
  //    }
  //  }
  //
  //  &__label {
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    position: absolute;
  //    top: -3.9rem;
  //    left: 50%;
  //    transform: translateX(-50%);
  //    background: $gray;
  //    color: $white;
  //    border: 2px solid $white;
  //    text-transform: uppercase;
  //    font-weight: $font-weight-bold;
  //    width: 7.8rem;
  //    height: 7.8rem;
  //    border-radius: 50%;
  //    box-shadow: rgba($black, .16) 0 .3rem .6rem;
  //    z-index: 1;
  //  }
  //}
}