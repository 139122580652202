.post-footer {
  background: $white;
  font-size: 1.2rem;
  font-weight: $font-weight-normal;

  &, & a {
    color: $gray;
    text-decoration: none;
  }

  a {
    display: block;

    &:not(:last-child) {
      margin-right: $bit-padding;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (min-width: $screen-sm-min) {
      justify-content: space-between;
    }
  }
}