@import 'styles/lib/fontawesome/variables';

//== Grid overrides
//=========================================================
$soda-container-max-width: 125rem;
$soda-gutter: 3rem;
$bit-padding: 1.5rem;

//== Spacers overrides
//=========================================================
$space-values: (
        0,
        2,
        5,
        7,
        10,
        15,
        20,
        30,
        40,
        50,
        75,
        100,
);

//== Colors
//=========================================================
$green: #6EA24D; // SamSam
$green-light: #AFB427; // SamSam
$green-lightest: #F1F6ED; // SamSam

$blue: #04B8F0; // SamSam
$blue-lightest: #E5F8FE; // SamSam

$black: #000000;
$gray-darkest: #1b332e;
$gray-darker: #2e554e;
$gray-dark: #40766d;
$gray: #5BA99B; // SamSam
$gray-light: #8cc3b9;
$gray-lighter: #add4cd; // SamSam
$gray-lightest: #DFEEEB; // SamSam
$white: #FFFFFF;

$color-primary: $green;
$font-color: $black;
$headings-color: $gray;

$color-names: (
        'green': $green,
        'green-light': $green-light,
        'green-lightest': $green-lightest,
        'black': $black,
        'gray-darkest': $gray-darkest,
        'gray-darker': $gray-darker,
        'gray-dark': $gray-dark,
        'gray' : $gray,
        'gray-light' : $gray-light,
        'gray-lighter' : $gray-lighter,
        'gray-lightest' : $gray-lightest,
        'white': $white,
        'primary': $color-primary,
        'font-color': $font-color,
        'blue': $blue,
        'blue-lightest': $blue-lightest,
);

//== Fonts
//=========================================================
$font-family-base: 'Ubuntu', sans-serif;
$font-family-headings: 'Ubuntu', sans-serif;
$font-family-font-awesome: 'Font Awesome 5 Pro';

$font-size-html: 62.5%;

$font-weight-semi-bold: 600;
$font-weight-extra-bold: 800;

$font-size-small-xxs: 1.4rem;
$font-size-small: 1.6rem;
$font-size-base-xxs: 1.8rem;
$font-size-base: 1.8rem;
$font-size-medium-xxs: 1.8rem;
$font-size-medium: 2rem;
$font-size-large-xxs: 2rem;
$font-size-large: 2.3rem;

$font-size-h1-xxs: 4.9rem; // SamSam
$font-size-h1: 7.3rem; // SamSam
$font-size-h2-xxs: 3.6rem; // SamSam
$font-size-h2: 5.6rem; // SamSam
$font-size-h3-xxs: 3rem; // SamSam
$font-size-h3: 3.8rem; // SamSam
$font-size-h4-xxs: 2.5rem; // SamSam
$font-size-h4: 3rem; // SamSam
$font-size-h5-xxs: 2rem;
$font-size-h5: 2.2rem;
$font-size-h6-xxs: $font-size-base-xxs;
$font-size-h6: $font-size-base;

$font-size-lead-xxs: $font-size-large-xxs;
$font-size-lead: $font-size-large;
$font-size-display-xxs: 3.5rem;
$font-size-display-sm: 5rem;
$font-size-display: 6rem;
$font-size-caption-xxs: $font-size-small-xxs;
$font-size-caption: $font-size-small;

$font-size-quote-xxs: 2.4rem;
$font-size-quote: 2.8rem;

$line-height-super-tight: 1.05;
$line-height-tight: 1.15;
$line-height-base: 1.55;
$line-height-quote: $line-height-base;

$line-height-base-computed: 3.2rem;

$text-sizes: (
                ('small', $font-size-small-xxs, $font-size-small),
                ('medium', $font-size-medium-xxs, $font-size-medium),
                ('large', $font-size-large-xxs, $font-size-large),
                ('display', $font-size-display-xxs, $font-size-display),
                ('base', $font-size-base-xxs, $font-size-base)
);

//== Border radius
//=========================================================
$border-radius: 1rem;
$border-radius-small: .2rem;
$border-radius-large: 3rem;

//== Navbar
//=========================================================
$navbar-mobile-height: 10rem;
$navbar-desktop-height: 15.9rem;
$mobile-menu-width: 100vw;

//== Transitions
//=========================================================
$transition-timing: .4s;
$transition-timing-function: ease-in-out;
$link-transition: border .2s, background .2s, color .2s, transform .2s, box-shadow .2s;
$button-transition: all .2s;
$visibility-transition: visibility .3s ease, opacity .3s ease;

//== Z-indexes
//=========================================================
$z-index-relative: 1;
$z-index-quicklinks: 996;
$z-index-cookie-bar: 997;
$z-index-site-overlay: 998;
$z-index-navbar: 999;
$z-index-mobile-menu: 1000;
$z-index-modal: 1001;

//== Slick-carousel overrides
//=========================================================
//$slick-font-path: '../vendor/slick-carousel/slick/fonts/';
//$slick-loader-path: '../vendor/slick-carousel/slick/';
//$slick-arrow-color: $blue;
//$slick-font-family: $font-family-font-awesome;

//== Font awesome variables
//=========================================================
$fa-font-path: '/fonts/fontawesome';
