.cb--contact {
  &__wrapper {
    position: relative;
    background-color: $gray;
    border-radius: 3rem;
    padding: 12rem 3.5rem 4.5rem 3.5rem;
    font-weight: $font-weight-normal;
    box-shadow: rgba($black, .1) 0 .3rem .12rem;
    @include responsive-font-size(((1.6rem), ($screen-sm-min, 1.8rem)));
    margin-top: 9.1rem;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
      padding: 12rem 6rem 5rem 6rem;
    }

    @media (min-width: $screen-md-min) {
      padding: 5rem 6rem;
    }

    &, & a:not(.button) {
      color: $white;
    }
  }

  &__title > * {
    @include responsive-font-size(((3rem), ($screen-sm-min, 3.8rem)));
    color: $white;
    font-weight: $font-weight-demi;
  }

  &__text {
    max-width: 70rem;

    @media (min-width: $screen-md-min) {
      max-width: 60%;
    }

    @media (min-width: $screen-lg-min) {
      max-width: 70rem;
    }
  }

  &__image {
    width: 18.2rem;
    height: 18.2rem;
    border-radius: 50%;
    border: .7rem solid $white;
    overflow: hidden;
    position: absolute;
    top: -9.1rem;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: rgba($black, .16) 0 .3rem .6rem;
    z-index: 1;

    @media (min-width: $screen-md-min) {
      width: 29.2rem;
      height: 29.2rem;
      left: unset;
      transform: unset;
      top: -5.7rem;
      right: 8.9rem;
      border-width: 1rem;
    }
  }
}