$cb-padding-mobile: 7rem;
$cb-padding-desktop: 10rem;

.cb {
  $this: &;

  padding-top: $cb-padding-mobile;
  padding-bottom: $cb-padding-mobile;

  @media (min-width: $screen-md-min) {
    padding-top: $cb-padding-desktop;
    padding-bottom: $cb-padding-desktop;
  }

  &:first-of-type {
    padding-top: $navbar-mobile-height;

    @media (min-width: $screen-sm-min) {
      padding-top: $navbar-desktop-height;
    }
  }

  &:last-of-type#{$this}--contact {
    padding-bottom: 3rem;
  }

  i.fas {
    color: $gray;
  }

  &[data-prev-cb-edge-bottom] {
    padding-top: $cb-padding-mobile + 5rem;

    @media (min-width: $screen-md-min) {
      padding-top: $cb-padding-desktop + 5rem;
    }
  }

  &[data-next-cb-edge-top] {
    padding-bottom: $cb-padding-mobile + 5rem;

    @media (min-width: $screen-md-min) {
      padding-bottom: $cb-padding-desktop + 5rem;
    }
  }

  &[data-font-color="green"] {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, i.fas {
      color: $green;
    }

    .button:not(.button--link) {
      @extend .button--green;
    }

    .button--link {
      @extend .button--link--green;
    }

    hr {
      border-color: $green;
    }

    .swiper {
      .swiper-pagination {
        background-color: rgba($green, .2) !important;

        .swiper-pagination-bullet-active {
          background-color: $green;
        }
      }
    }
  }

  &[data-font-color="blue"] {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, i.fas {
      color: $blue;
    }

    .button:not(.button--link) {
      @extend .button--blue;
    }

    .button--link {
      @extend .button--link--blue;
    }

    hr {
      border-color: $blue;
    }

    .swiper {
      .swiper-pagination {
        background-color: rgba($blue, .2) !important;

        .swiper-pagination-bullet-active {
          background-color: $blue;
        }
      }
    }
  }

  &[data-font-color="white"] {
    color: $white;

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, i.fas {
      color: $white;
    }

    .button:not(.button--link) {
      @extend .button--white;
    }

    .button--link {
      @extend .button--link--white;
    }

    hr {
      border-color: $white;
    }

    .swiper {
      .swiper-pagination {
        background-color: rgba($white, .2) !important;

        .swiper-pagination-bullet-active {
          background-color: $white;
        }
      }
    }
  }

  &[data-bg-color="white"] {
    background-color: $white;

    & + & {
      padding-top: 0;
    }
  }

  &[data-bg-color="gray-lightest"] {
    background-color: $gray-lightest;

    & + & {
      padding-top: 0;
    }
  }

  &[data-bg-color="green-lightest"] {
    background-color: $green-lightest;

    & + & {
      padding-top: 0;
    }
  }

  &[data-bg-color="blue-lightest"] {
    background-color: $blue-lightest;

    & + & {
      padding-top: 0;
    }
  }

  &[data-bg-color="gray"] {
    background-color: $gray;

    & + & {
      padding-top: 0;
    }
  }

  &[data-bg-color="green"] {
    background-color: $green;

    & + & {
      padding-top: 0;
    }
  }

  &[data-bg-color="blue"] {
    background-color: $blue;

    & + & {
      padding-top: 0;
    }
  }

  &[data-edge-top="1"], &[data-edge-top="2"], &[data-edge-top="3"], &[data-edge-top="4"] {
    @include block-edge('top');
  }

  &[data-edge-top="1"]::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 436' transform='translate(0 474)' fill='%23fff' stroke='%23707070' d='M0 0h1920v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 78' transform='translate(0 -474)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M960 473.8c1629.325 0 2950.153 370.448 2950.153 827.419-4.2 4.4-5901.724-11.407-5900.306 0C-1990.153 844.248-669.324 473.8 960 473.8Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  }

  &[data-edge-top="2"]::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 436' transform='translate(0 474)' fill='%23fff' stroke='%23707070' d='M0 0h1920v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 78' transform='rotate(180 960 262)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M960 473.8c1629.325 0 2950.153 370.448 2950.153 827.419-4.2 4.4-5901.724-11.407-5900.306 0C-1990.153 844.248-669.324 473.8 960 473.8Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  }

  &[data-edge-top="3"]::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.6'%3E%3Cpath data-name='Subtraction 7' d='M1919.997 51.599c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592q-249.442-5.833-498.956 0c-81.412 1.912-163.4 4.8-243.7 8.593-79.41 3.748-159.186 8.47-237.112 14.037C152.556 37.861 75.271 44.337-.003 51.599v-51.6h1920v51.6Z' fill='%23fff'/%3E%3C/svg%3E");
  }

  &[data-edge-top="4"]::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.6'%3E%3Cpath data-name='Subtraction 7' d='M.003.001c75.275 7.263 152.56 13.739 229.708 19.25 77.929 5.566 157.7 10.289 237.112 14.037 80.308 3.79 162.3 6.681 243.7 8.592q249.442 5.833 498.956 0c81.412-1.912 163.4-4.8 243.7-8.593 79.41-3.748 159.186-8.47 237.112-14.037 77.153-5.511 154.438-11.987 229.712-19.249v51.6H.003V.001Z' fill='%23fff'/%3E%3C/svg%3E");
  }

  &[data-edge-bottom="1"], &[data-edge-bottom="2"], &[data-edge-bottom="3"], &[data-edge-bottom="4"] {
    @include block-edge('bottom');
  }

  &[data-edge-bottom="1"]::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 436' transform='translate(0 474)' fill='%23fff' stroke='%23707070' d='M0 0h1920v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 78' transform='translate(0 -474)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M960 473.8c1629.325 0 2950.153 370.448 2950.153 827.419-4.2 4.4-5901.724-11.407-5900.306 0C-1990.153 844.248-669.324 473.8 960 473.8Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  }

  &[data-edge-bottom="2"]::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 436' transform='translate(0 474)' fill='%23fff' stroke='%23707070' d='M0 0h1920v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 78' transform='rotate(180 960 262)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M960 473.8c1629.325 0 2950.153 370.448 2950.153 827.419-4.2 4.4-5901.724-11.407-5900.306 0C-1990.153 844.248-669.324 473.8 960 473.8Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  }

  &[data-edge-bottom="3"]::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.6'%3E%3Cpath data-name='Subtraction 7' d='M1919.997 51.599c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592q-249.442-5.833-498.956 0c-81.412 1.912-163.4 4.8-243.7 8.593-79.41 3.748-159.186 8.47-237.112 14.037C152.556 37.861 75.271 44.337-.003 51.599v-51.6h1920v51.6Z' fill='%23fff'/%3E%3C/svg%3E");
  }

  &[data-edge-bottom="4"]::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.6'%3E%3Cpath data-name='Subtraction 7' d='M.003.001c75.275 7.263 152.56 13.739 229.708 19.25 77.929 5.566 157.7 10.289 237.112 14.037 80.308 3.79 162.3 6.681 243.7 8.592q249.442 5.833 498.956 0c81.412-1.912 163.4-4.8 243.7-8.593 79.41-3.748 159.186-8.47 237.112-14.037 77.153-5.511 154.438-11.987 229.712-19.249v51.6H.003V.001Z' fill='%23fff'/%3E%3C/svg%3E");
  }
}
